<template>
  <div>
    <b-card-actions
        title="Filters"
        action-collapse
    >
      <div>
        <!-- search input -->
        <template v-if="$permissionAbility(ATTENDANCE_RECONCILIATION_SHOW, permissions)">
          <div>
            <b-row>
              <b-col
                  md="6"
                  lg="4"
                  xs="12"
              >
                <v-select
                    v-model="selectEmpId"
                    :options="filterEmpIdOption"
                    :reduce="(item) => item.id"
                    label="name"
                    placeholder="Select Employee"
                    class="mb-1 custom-font"
                    @input="loadReconciliation('user')"
                >
                  <template #option="data">
                    <UserSelect :user="data" />
                  </template>
                </v-select>
              </b-col>
              <b-col
                  md="6"
                  lg="4"
                  xs="12"
              >
                <v-select
                    v-model="selectDivisionId"
                    :options="filterDivisionIdOption"
                    :reduce="(item) => item.id"
                    label="name"
                    placeholder="Select Division"
                    class="mb-1 custom-font"
                    @input="loadReconciliation('division')"
                />
              </b-col>
              <b-col
                  md="6"
                  lg="4"
                  xs="12"
              >
                <v-select
                    v-model="selectDepartmentId"
                    :options="filterDepartmentIdOption"
                    :reduce="(item) => item.id"
                    label="name"
                    placeholder="Select Department"
                    class="mb-1 custom-font"
                    @input="loadReconciliation('department')"
                />
              </b-col>
              <b-col
                  md="6"
                  lg="4"
                  xs="12"
              >
                <v-select
                    v-model="selectStatus"
                    :options="filterStatusOption"
                    :reduce="(item) => item.id"
                    label="name"
                    placeholder="Select Status"
                    class="mb-1 custom-font"
                    @input="loadReconciliation('status')"
                />
              </b-col>
              <b-col
                  md="6"
                  lg="4"
                  xs="12"
              >
                <v-select
                    v-model="selectEmpTypeId"
                    :options="filterEmpTypeIdOption"
                    :reduce="(item) => item.id"
                    label="name"
                    placeholder="Select Employee Types"
                    class="mb-1 custom-font"
                    @input="loadReconciliation('empType')"
                />
              </b-col>
              <b-col
                  md="2"
                  lg="4"
                  xs="12"
              >

                <flat-pickr
                    v-model="filterRangeDate"
                    class="form-control"
                    placeholder="Select Date Range"
                    :config="{ mode: 'range'}"
                    @input="loadReconciliation('date')"
                />
              </b-col>
            </b-row>
          </div>
        </template>
      </div>
    </b-card-actions>

    <b-card>
      <div>
        <!-- search input -->
        <div class="custom-search d-flex align-items-center justify-content-end">
          <div
              class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
          >

            <template v-if="
            $permissionAbility(ATTENDANCE_RECONCILIATION_APPLY_EMPLOYEE,permissions)">
              <b-button
                  v-if="
            $permissionAbility(ATTENDANCE_RECONCILIATION_APPLY_EMPLOYEE,permissions)"
                  class="flex-shrink-0"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  v-on:click="showApplyModal('apply-employee')"
              >
                Apply For Employee
              </b-button>
            </template>
            <template v-else>
              <b-button
                  v-if="
            $permissionAbility(ATTENDANCE_RECONCILIATION_APPLY,permissions)"
                  class="flex-shrink-0 ml-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  v-on:click="showApplyModal"
              >
                Apply
              </b-button>
            </template>
          </div>
        </div>
        <!-- table -->
        <vue-good-table
            styleClass="vgt-table table-custom-style condensed striped"
            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            :totalRows="totalRecords"
            :isLoading.sync="isLoading"
            :rows="rows"
            :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'attendance_date', type: 'desc' }],
        }"
            :columns="columns"
            :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"

        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: head -->
            <!-- Column: head -->
            <span v-if="props.column.field === 'name'">

            <template v-if="props.row?.user_id">
              <span class="cursor-pointer" @click="onShowPage(props.row.attendance_id)">
                <UserCard :user="props?.row?.user?.data" />
              </span>
            </template>

          </span>

            <!-- Column: old_attendance -->
            <template v-if="props.column.field === 'format_old_attendance'">
              <!-- attendance_status -->
              <template v-if="props.row?.old_attendance?.attendance_status">
                <div>
                <span>
                   Status :
                  <b-badge variant="light-primary" pill>
                    {{ props?.row?.old_attendance?.attendance_status?.name }}
                  </b-badge>
                </span>
                </div>
              </template>

              <!-- first_check_in -->
              <template v-if="props.row?.old_attendance?.first_check_in">
                <div>
                <span>
                  First Check In : <b>
                  {{
                    formatFnTableCheckIn(
                      props.row?.old_attendance?.first_check_in
                    )
                  }}
                </b>
                </span>
                </div>
              </template>

              <!-- last_check_out -->
              <template v-if="props.row?.old_attendance?.last_check_out">
                <div>
                <span>
                  Last Check Out : <b>
                  {{
                    formatFnTableCheckIn(
                      props.row?.old_attendance?.last_check_out
                    )
                  }}
                  </b>
                </span>
                </div>
              </template>

              <!-- expected_duty_minutes -->
              <template v-if="props.row?.old_attendance?.expected_duty_minutes">
                <div>
                <span>
                  Expected Duty Hours :
                  <b>
                  {{
                      formatMinutesToHours(
                          props.row?.old_attendance?.expected_duty_minutes
                      )
                    }}
                  </b>
                </span>
                </div>
              </template>

              <!-- total_worked_minutes -->
              <template v-if="props.row?.old_attendance?.total_worked_minutes">
                <div>
                <span>
                  Total Work Hours : <b>
                  {{
                    formatMinutesToHours(
                      props.row?.old_attendance?.total_worked_minutes
                    )
                  }}
                  </b>
                </span>
                </div>
              </template>

              <!-- extra_less_duty_minutes -->
              <template v-if="props.row?.old_attendance?.extra_less_duty_minutes">
                <div>
                <span>
                  Extra/Less Duty Hours : <b>
                  {{
                    formatMinutesToHours(
                      props.row?.old_attendance?.extra_less_duty_minutes
                    )
                  }} </b>
                </span>
                </div>
              </template>

              <!-- over_time_minutes -->
              <template v-if="props.row?.old_attendance?.over_time_minutes">
                <div>
                <span>
                   Over Time Hours :
                  <b>{{
                    formatMinutesToHours(
                      props.row?.old_attendance?.over_time_minutes
                    )
                  }} </b>
                </span>
                </div>
              </template>
            </template>

            <!-- Column: old_attendance -->
            <template v-if="props.column.field === 'format_updated_attendance'">
              <!-- attendance_status -->
              <template v-if="props?.row?.attendanceStatus?.data?.name">
                <div>
                <span>
                  <b> Status : </b>
                  <b-badge variant="light-primary" pill>
                    {{ props?.row?.attendanceStatus?.data?.name }}
                  </b-badge>
                </span>
                </div>

              </template>

              <!-- first_check_in -->
              <template v-if="props.row?.first_check_in">
                <div>
                <span>
                  Check In :
                  <b>{{ formatFnTableCheckIn(props.row?.first_check_in) }}</b>
                </span>
                </div>
              </template>

              <!-- last_check_out -->
              <template v-if="props.row?.last_check_out">
                <div>
                <span>
                  Check Out :
                  <b>{{ formatFnTableCheckIn(props.row?.last_check_out) }}</b>
                </span>
                </div>
              </template>

              <template v-if="props.row?.first_check_in && props.row?.last_check_out">
                <div>
                <span>
                 Request for:
                   <b><b-badge variant="light-success">
                    {{ formatTimeDff(props.row?.first_check_in, props.row?.last_check_out) }} Hours
                  </b-badge></b>

                </span>
                </div>
              </template>

              <!-- expected_duty_minutes -->
              <template v-if="props.row?.expected_duty_minutes">
                <div>
                <span>
                  Expected Duty Hours :
                  <b>{{ formatMinutesToHours(props.row?.expected_duty_minutes) }}</b>
                </span>
                </div>
              </template>

              <!-- total_worked_minutes -->
              <template v-if="props.row?.total_worked_minutes">
                <div>
                <span>
                   Total Work Hours :
                  <b>{{ formatMinutesToHours(props.row?.total_worked_minutes) }}</b>
                </span>
                </div>
              </template>

              <!-- extra_less_duty_minutes -->
              <template v-if="props.row?.extra_less_duty_minutes">
                <div>
                <span>
                   Extra/Less Duty Hours :
                  <b>{{ formatMinutesToHours(props.row?.extra_less_duty_minutes) }}</b>
                </span>
                </div>
              </template>

              <!-- over_time_minutes -->
              <template v-if="props.row?.over_time_minutes">
                <div>
                <span>
                  Over Time Hours :
                  <b> {{ formatMinutesToHours(props.row?.over_time_minutes) }} </b>
                </span>
                </div>
              </template>

              <template>
                <b-button
                    v-b-tooltip.hover
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="btn-icon"
                    :title='props.row?.description'
                >
                  <feather-icon class="btn-icon" icon="InfoIcon" />
                </b-button>
              </template>
            </template>

            <!-- Column: reconciliation -->
            <span v-if="props.column.field === 'format_status'">
            <b-badge :variant="statusVariant(props.row?.status)">
              <span v-if="props.row?.status === ''">
                Rejected
              </span>
              <span v-else>
                {{ props.row?.status }}
              </span>
            </b-badge>
            <br />

            <template v-if="props.row?.rejection_remarks">
              <b-button
                  v-b-tooltip.hover
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="btn-icon"
                  :title='props.row?.rejection_remarks'
              >
                <feather-icon class="btn-icon" icon="InfoIcon" />
              </b-button>
            </template>
          </span>

          <!-- description -->
          <span v-if="props.column.field === 'format_description'">
            <template>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="btn-icon"
                  @click="showDescription(props.row?.description)"
              >
                <feather-icon icon="InfoIcon" />
              </b-button>
            </template>
          </span>

            <!-- Column: Action -->
            <span  v-if="props.column.field === 'action'">

              <template
                  v-if="$permissionAbility(ATTENDANCE_RECONCILIATION_SHOW, permissions)"
              >
                <span
                @click="onShowPage(props.row.attendance_id)"
                @contextmenu.prevent="onOpenInNewTab(props.row.id)"
                >
                  <feather-icon
                      v-b-tooltip.hover
                      icon="EyeIcon"
                      class="mr-50 custom-icon cursor-pointer"
                      title="View"
                      size="16"
                  />
                </span>
              </template>

              <template v-if="props.row?.status === 'Pending'">
                <template v-if="reconciliationPermissionCheck(props.row)">
                    <span @click="onApprove(props.row)">
                      <feather-icon
                          v-b-tooltip.hover
                          icon="CheckIcon"
                          class="mr-50 custom-icon cursor-pointer"
                          title="Approve"
                          size="16"
                      />
                    </span>
                </template>

                <template v-if="
                      $permissionAbility(
                        ATTENDANCE_RECONCILIATION_CANCEL,
                        permissions
                      )
                    ">
                    <span @click="rejectModalOpen(props.row)">
                      <feather-icon
                          v-b-tooltip.hover
                          icon="XIcon"
                          class="mr-50 custom-icon cursor-pointer"
                          title="Reject"
                          size="16"
                      />
                    </span>
                </template>
              </template>



          </span>

            <!-- Column: Common -->
            <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                    v-model="pageLength"
                    :options="['10', '25', '50', '100', '500']"
                    class="mx-1"
                    @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>



      <!-- apply modal -->
      <b-modal
          id="modal-apply-form"
          centered
          :title="
        modelType == 'editModel'
          ? ' Reconciliation Application Details'
          : 'Reconciliation Request'
      "
          hide-footer
          @hidden="hiddenApplyModal"
          no-close-on-backdrop
          size="lg"
      >
        <validation-observer ref="applyValidation">
          <b-form v-on:submit.prevent="applyFromSubmit">

            <b-row class="justify-content-center">
              <b-col cols="12" md="8" lg="6">
                <div class="text-center">

                  <template v-if="userData?.user_id">
                    <b-avatar default="''" :src="userData?.user?.data?.avatar === '' ? '/avatar.svg' : userData?.user?.data?.avatar" >
                    </b-avatar>
                      <h4>{{ userData?.user?.data?.name }} <b-badge variant="light-secondary">{{
                          userData?.user?.data?.employee_number
                        }}</b-badge></h4>
                    <a :href="'mailto:' + (userData?.user?.data?.email || '')" class="font-small-2 font-weight-bold">
                      {{ userData?.user?.data?.email }}
                    </a> <br>
                    <b-badge variant="light-primary">
                      {{ userData?.user?.data?.department?.name }}
                    </b-badge> <br>
                    <b-badge variant="light-primary">
                      {{ userData?.user?.data?.designation?.name }}
                    </b-badge>
                  </template>
                </div>
              </b-col>
            </b-row>

          <template v-if="applyEmployee">
            <b-form-group label="Employee" label-for="user-id">
              <ValidationProvider
                  name="employee"
                  v-slot="{ errors }"
                  vid="user_id"
                  rules="required"
              >
                <v-select
                    id="user-id"
                    placeholder="Select Employee"
                    v-model="selectUserId"
                    :options="userIdOption"
                    :reduce="(country) => country.id"
                    label="name"
                    @input=""
                >
                  <template #option="data">
                    <UserSelect :user="data" />
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </template>

            <!--          <template v-if="applyEmployee">-->
            <!-- attendance date-->
            <b-form-group label="Reconciliation Date*">
              <ValidationProvider
                  name="date"
                  v-slot="{ errors }"
                  vid="attendance_date"
                  rules="required"
              >
                <flat-pickr
                    v-model="attendanceDate"
                    class="form-control"
                    placeholder="Select Date"
                    :state="errors.length > 0 ? false : null"
                    id="attendance-date"
                    @context="onContextDate"
                    v-on:input="getAttendanceActivity"
                    :date-disabled-fn="futureDateDisable"
                    :disabled = "applyEmployee && !selectUserId"
                    :config="attendanceDateConfig"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
            <!--          </template>-->

            <template v-if="items.length > 0">
              <b-table responsive :items="items" :fields="fields" class="mb-0">
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  {{ data.value }}
                </template>
              </b-table>
            </template>
            <template v-else>
              <template v-if="attendanceDate === ''">
                <h6 class="text-danger">Please Select a Date First</h6>
              </template>
              <template v-else>
                <h6 class="text-danger">No Attendance Activity Data Found</h6>
              </template>
            </template>

            <b-row>
              <b-col cols="6">
                <b-form-group label="Requested Check In*">
                  <validation-provider
                      #default="{ errors }"
                      name="check in"
                      vid="check_in"
                      rules="required"
                  >
                    <flat-pickr
                        name="check-in"
                        id="check_in"
                        v-model="checkIn"
                        class="form-control"
                        placeholder="09:00 AM"
                        :config="{
                          enableTime: true,
                          noCalendar: true,
                          static: true,
                          dateFormat: 'H:i:S',
                          altInput: true,
                          altFormat: 'h:i K',
                          minuteIncrement: 1
                        }"
                        :disabled = "!attendanceDate"
                        :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group label="Requested Check Out*">
                  <validation-provider
                    #default="{ errors }"
                    name="check out"
                    vid="check_out"
                    rules="required"
                  >
                    <flat-pickr
                        name="check-out"
                        id="check_out"
                        v-model="checkOut"
                        class="form-control"
                        placeholder="06:00 PM"
                        :config="{
                          enableTime: true,
                          noCalendar: true,
                          static: true,
                          dateFormat: 'H:i:S',
                          altInput: true,
                          altFormat: 'h:i K',
                          minuteIncrement: 1
                        }"
                        :disabled = "!attendanceDate"
                        :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- description -->
            <b-form-group
                label="Description*"
                label-for="description"
            >
              <validation-provider
                  #default="{ errors }"
                  name="description"
                  vid="description"
                  rules="required"
              >
                <b-form-textarea
                    id="description"
                    v-model="description"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Description"
                    rows="3"
                    :disabled="modelType === 'editModel'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <template v-if="modelType == 'editModel'">
              <b-form-group
                  label="Remarks"
                  :class="
              $permissionAbility(ATTENDANCE_RECONCILIATION_APPROVE, permissions)
                ? 'required-label'
                : ''
            "
                  label-for="remarks"
              >
                <validation-provider
                    #default="{ errors }"
                    name="remarks"
                    vid="remarks"
                    :rules="
                $permissionAbility(
                  ATTENDANCE_RECONCILIATION_APPROVE,
                  permissions
                )
                  ? 'required'
                  : ''
              "
                >
                  <b-form-textarea
                      id="required-remarks"
                      v-model="approvalRemarks"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Remarks"
                      rows="3"
                      :disabled="
                  $permissionAbility(
                    ATTENDANCE_RECONCILIATION_APPROVE,
                    permissions
                  )
                    ? false
                    : true
                "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>

            <!-- loading button -->
            <template v-if="isApplyFormSubmitLoading">
              <b-button class="float-right" variant="primary" disabled>
                <b-spinner small />
                Loading...
              </b-button>
            </template>

            <!-- submit button -->
            <template v-if="!approveButton && !isApplyFormSubmitLoading">
              <b-button
                  type="submit"
                  class="float-right"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
              >
                Submit
              </b-button>
            </template>

            <!-- approve button -->
            <template v-if="approveButton && !isApplyFormSubmitLoading">
              <b-button
                  class="float-right"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  @click="handleApproveClick"
              >
                Approve
              </b-button>
            </template>
          </b-form>
        </validation-observer>
      </b-modal>

    <!-- Reject  -->
    <b-modal
      id="modal-reject-form"
      centered
      title= "Reconciliation Reject Remarks"
      hide-footer
      @hidden="hiddenRejectModal"
      no-close-on-backdrop
      size="lg"
    >
      <validation-observer ref="rejectValidation">
        <b-form v-on:submit.prevent="rejectFromSubmit">

          <b-row class="justify-content-center">
              <b-col cols="12" md="8" lg="6">
                <div class="text-center">

                  <template v-if="userData?.user_id">
                    <b-avatar default="''" :src="userData?.user?.data?.avatar === '' ? '/avatar.svg' : userData?.user?.data?.avatar" >
                    </b-avatar>
                    <h4>{{ userData?.user?.data?.name }} <b-badge variant="light-secondary">{{
                        userData?.user?.data?.employee_number
                      }}</b-badge></h4>
                    <a :href="'mailto:' + (userData?.user?.data?.email || '')" class="font-small-2 font-weight-bold">
                      {{ userData?.user?.data?.email }}
                    </a> <br>
                    <b-badge variant="light-primary">
                      {{ userData?.user?.data?.department?.name }}
                    </b-badge> <br>
                    <b-badge variant="light-primary">
                      {{ userData?.user?.data?.designation?.name }}
                    </b-badge>
                  </template>
                </div>
              </b-col>
            </b-row>

          <!-- description -->
          <b-form-group
            label="Rejection Remarks*"
            label-for="rejection_remarks"
          >
            <validation-provider
              #default="{ errors }"
              name="Rejection Remarks"
              vid="rejection_remarks"
              rules="required"
            >
              <b-form-textarea
                id="rejection_remarks"
                v-model="rejectionRemarks"
                :state="errors.length > 0 ? false : null"
                placeholder="Rejection Remarks"
                rows="3"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- submit button -->
          <template >
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>


        </b-form>
      </validation-observer>
    </b-modal>

    <!-- RECONCILIATION modal -->
    <template>
      <b-modal
        id="modal-description-view"
        centered
        :title="isPopUpDescription ? 'Description' : 'Remarks'"
        hide-footer
        @hidden="hiddenDescriptionModal"
        no-close-on-backdrop
      >
        {{ description }}
      </b-modal>
    </template>
  </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BCol,
  BRow,
  BFormDatepicker,
  BLink,
  BFormTextarea,
  BFormTimepicker,
  BTable, VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max, size } from "@validations";
import flatPickr from 'vue-flatpickr-component';


import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import { formatDateRange, minutesToHours } from '@/helpers/helpers'
import {
  APPROVED,
  CANCELED,
  PENDING,
} from "@/helpers/constant/attendanceConstant";

import {
  ATTENDANCE_RECONCILIATION_SHOW,
  ATTENDANCE_RECONCILIATION_APPROVE,
  ATTENDANCE_RECONCILIATION_CANCEL,
  ATTENDANCE_RECONCILIATION_EDIT,
  ATTENDANCE_RECONCILIATION_APPLY,
  ATTENDANCE_RECONCILIATION_APPLY_EMPLOYEE,
  ATTENDANCE_RECONCILIATION_SELF_APPROVE
} from "@/helpers/permissionsConstant";
import UserCard from "@/layouts/components/UserCard.vue";
import UserSelect from '@/layouts/components/UserSelect.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { REJECTED } from '@/helpers/constant/leaveRequestStatusConstant'

export default {
  name: "ReconciliationView",
  components: {
    BCardActions,
    UserSelect,
    BForm,
    BButton,
    BCard,
    UserCard,
    VueGoodTable,
    flatPickr,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BSpinner,
    BCol,
    BRow,
    BFormDatepicker,
    BLink,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BFormTimepicker,
    BTable
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },

  async created() {

    try {

      const currentDate = new Date()

      const todaysDate = `${currentDate.getFullYear()}-${(
          currentDate.getMonth() + 1
      )
          .toString()
          .padStart(2, '0')}-${currentDate
          .getDate()
          .toString()
          .padStart(2, '0')}`

      this.filterStartDate = todaysDate
      this.filterEndDate = todaysDate

      const [employeeTypes, divisions, departments, employees] = await Promise.all([
        this.getEmployeeTypes(),
        this.getDivisions(),
        this.getDepartments(),
        this.getActiveEmployees(),
      ])

      // employee type
      this.filterEmpTypeIdOption = (employeeTypes?.data?.data || []).map(
          item => {
            const name = item?.name
            return {
              name,
              id: item?.id,
            }
          },
      )

      // department
      this.filterDepartmentIdOption = (departments?.data?.data || []).map(
          item => {
            const name = item?.name

            return {
              name,
              divisionId: item?.division_id,
              id: item?.id,
            }
          },
      )

      // division
      this.filterDivisionIdOption = (divisions?.data?.data || []).map(
          item => {
            const name = item?.name
            return {
              name,
              id: item?.id,
            }
          },
      )

      // employee
      this.filterEmpIdOption = (employees?.data?.data || []).map((item) => {
        return {
          avatar: item?.avatar,
          name: item?.name,
          email: item?.email,
          mobile: item?.mobile,
          employee_number: item?.employee_number,
          id: item?.id,
          departmentId: item?.department_id
        }
      })

      this.userIdOption = this.filterEmpIdOption

    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }

  },

  data() {
    return {
      ATTENDANCE_RECONCILIATION_SHOW,
      ATTENDANCE_RECONCILIATION_APPROVE,
      ATTENDANCE_RECONCILIATION_APPLY,
      ATTENDANCE_RECONCILIATION_APPLY_EMPLOYEE,
      ATTENDANCE_RECONCILIATION_SELF_APPROVE,
      ATTENDANCE_RECONCILIATION_CANCEL,
      ATTENDANCE_RECONCILIATION_EDIT,
      APPROVED,
      CANCELED,
      PENDING,
      REJECTED,

      applyEmployee: false,
      $userId: "",
      selectStatus: "",
      filterStatusOption: [
        {
          id: 'Pending',
          name: 'Pending'
        },
        {
          id: 'Approved',
          name: 'Approved'
        },
        {
          id: 'Rejected',
          name: 'Rejected'
        },
      ],
      filterEmpTypeIdOption: [],
      selectEmpTypeId: '',
      filterDivisionIdOption: [],
      selectDivisionId: '',
      filterDepartmentIdOption: [],
      selectDepartmentId: '',
      filterEmpIdOption: [],
      selectEmpId: '',
      filterStartDate: '',
      filterEndDate: '',
      todaysDate: '',

      filterRangeDate: null,
      userIdOption: [],
      selectUserId: "",
      // apply form
      attendanceDate: "",
      description: "",
      isApplyFormSubmitLoading: false,
      checkIn: "",
      checkOut: "",
      modelType: "",
      approvalRemarks: "",
      approveButton: false,
      timePicker: null,

      attendanceDateConfig: { maxDate: new Date(), static: true },

      // end apply form

      rejectionRemarks: "",
      rejectReconciliationId: "",
      userData: "",

      // reconciliation form
      attendanceReconciliationsId: "",
      firstCheckIn: "",
      lastCheckOut: "",
      expectedDutyHours: "",
      totalWorkedHours: "",
      overTimeHours: "",
      remarks: "",
      isUpdateReconciliationFormSubmitLoading: false,
      // end reconciliation form
      fields: ["check_in", "check_out", "worked_hour"],
      items: [],
      pageLength: 10,
      columns: [
        {
          label: "Date",
          field: "attendance_date",
          formatFn: this.formatDate,
          sortable: true,
        },
        {
          label: "Name",
          field: "name",
          sortable: false,
        },
        {
          label: "Existing Attendance",
          field: "format_old_attendance",
          sortable: false,
        },
        {
          label: "Reconciliation Request",
          field: "format_updated_attendance",
          sortable: false,
        },

        {
          label: "Status",
          field: "format_status",
          sortable: false,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      isPopUpDescription: false,
      rows: [],
      delayTimer: null,
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [{ field: "first_check_in", type: "desc" }],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
      authUser: "userModule/getUser",
    }),
  },

  methods: {

    async loadReconciliation(value) {
      if (value === 'division') {
        this.selectDepartmentId = ''
        this.selectEmpId = ''
        if (this.selectDivisionId) {
          const departments = await this.getDepartments()

          this.filterDepartmentIdOption = (departments?.data?.data || []).map(
              item => {
                const name = item?.name

                return {
                  name,
                  divisionId: item?.division_id,
                  id: item?.id,
                }
              },
          )

          this.filterDepartmentIdOption = this.filterDepartmentIdOption.filter(
              item => item?.divisionId === this.selectDivisionId,
          )
        } else {
          const departments = await this.getDepartments()

          this.filterDepartmentIdOption = (departments?.data?.data || []).map(
              item => {
                const name = item?.name

                return {
                  name,
                  divisionId: item?.division_id,
                  id: item?.id,
                }
              },
          )
        }
      }

      if (value === 'department') {
        this.selectEmpId = ''
        if (this.selectDepartmentId) {
          const employees = await this.getActiveEmployees()

          this.filterEmpIdOption = (employees?.data?.data || []).map(item => {
            const name = `${item?.name} (${item?.email})`
            return {
              avatar: item?.avatar,
              name: item?.name,
              email: item?.email,
              mobile: item?.mobile,
              employee_number: item?.employee_number,
              id: item?.id,
              departmentId: item?.department_id,
            }
          })

          this.filterEmpIdOption = this.filterEmpIdOption.filter(
              item => item?.departmentId === this.selectDepartmentId,
          )
        } else {
          const employees = await this.getActiveEmployees()

          this.filterEmpIdOption = (employees?.data?.data || []).map(item => ({
            avatar: item?.avatar,
            name: item?.name,
            email: item?.email,
            mobile: item?.mobile,
            employee_number: item?.employee_number,
            id: item?.id,
            departmentId: item?.department_id,
          }))
        }
      }

      await this.loadItems()
    },


    futureDateDisable(ymd, date){
      // Get current date
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0); // Set time to midnight for accurate comparison

      // Check if the given date is in the past (excluding today)
      if (date > currentDate) {
        return true;
      }
    },

    formattedTime(value) {
      if (value == 0) return "";
      return this.$moment
        .utc(value * 60 * 1000) // Convert minutes to milliseconds
        .format("H:mm");
    },

    async getUsers() {
      return await this.$api.get("api/users/all?include=designation");
    },

    reconciliationPermissionCheck(row) {
      if (
        this.$permissionAbility(
          ATTENDANCE_RECONCILIATION_APPROVE,
          this.permissions
        )
      ) {
        //if self application then on default permission false except if has self approve
        if (this.authUser.id === row.user.data.id) {
          if (
            this.$permissionAbility(
              ATTENDANCE_RECONCILIATION_SELF_APPROVE,
              this.permissions
            )
          ) {
            return true;
          }

          return false;
        }

        return true;
      }

      return false;
    },

    async onShow(value) {
      this.modelType = "editModel";
      this.attendanceReconciliationsId = value?.id;
      this.attendanceDate = value?.attendance_date;
      this.checkIn = value?.first_check_in;
      this.checkOut = value?.last_check_out;
      this.description = value?.description;
      this.remarks = value?.remarks;
      this.userData = value;

      const attendanceActivities = await this.$api.get(
        `api/attendance-activities/${value?.user_id}/${value?.attendance_date}`
      );

      const checkInCheckOutLog = attendanceActivities?.data?.data.sort(
        (a, b) => {
          const timeA = new Date(`${a?.date} ${a?.check_in}`);
          const timeB = new Date(`${b?.date} ${b?.check_in}`);

          // Compare and return the result
          return timeA - timeB;
        }
      );

      this.items = attendanceActivities?.data?.data.map((item) => {
        return {
          ...item,
          check_in: this.$moment(item?.check_in, "HH:mm:ss").format("LTS"),
          check_out: this.$moment(item?.check_out, "HH:mm:ss").format("LTS"),
          worked_hour: this.formattedTime(item?.worked_minutes),
        };
      });
      this.$bvModal.show("modal-apply-form");
    },

    applyFromSubmit() {
      this.$refs.applyValidation.validate().then(async (success) => {
        if (success) {
          try {
            if (this.modelType == "editModel") {
              const isRemarksDisabled =
                document.getElementById("required-remarks").disabled;

              await this.$api.put(
                `api/attendances/reconciliations/${this.attendanceReconciliationsId}`,
                {
                  attendance_date: this.attendanceDate,
                  description: this.description,
                  check_in: this.checkIn,
                  check_out: this.checkOut,
                  is_remarks_disabled: isRemarksDisabled,
                  remarks: this.approvalRemarks,
                }
              );
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Reconciliation Successfully Updated",
                },
              });

              try {
                await this.$api.post(
                  `api/attendances/reconciliations/${value?.id}/approve`
                );

                this.loadItems();

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Successfully Approved",
                  },
                });
              } catch (error) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error",
                    icon: "BellIcon",
                    variant: "danger",
                    text: error?.response?.data?.message,
                  },
                });
              }
            } else {

              this.isApplyFormSubmitLoading = true
              await this.$api.post("api/attendances/reconciliations/apply", {
                attendance_date: this.attendanceDate,
                description: this.description,
                check_in: this.checkIn,
                check_out: this.checkOut,
                user_id: this.selectUserId
              });

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Reconciliation Successfully Applied",
                },
              });
            }

            this.hiddenApplyModal();

            this.loadItems();
          } catch (error) {
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.applyValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          } finally {
            this.isApplyFormSubmitLoading = false;
          }
        }
      });
    },

    applyEmployeeFromSubmit() {
      this.$refs.applyValidation.validate().then(async (success) => {
        if (success) {
          try {
            if (this.modelType == "editModel") {
              const isRemarksDisabled =
                document.getElementById("required-remarks").disabled;

              await this.$api.put(
                `api/attendances/reconciliations/${this.attendanceReconciliationsId}`,
                {
                  attendance_date: this.attendanceDate,
                  description: this.description,
                  check_in: this.checkIn,
                  check_out: this.checkOut,
                  is_remarks_disabled: isRemarksDisabled,
                  remarks: this.approvalRemarks,
                }
              );
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Reconciliation Successfully Updated",
                },
              });

              try {
                this.isApplyFormSubmitLoading = true;

                await this.$api.post(
                  `api/attendances/reconciliations/${value?.id}/approve`
                );

                this.loadItems();

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Successfully Approved",
                  },
                });
              } catch (error) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error",
                    icon: "BellIcon",
                    variant: "danger",
                    text: error?.response?.data?.message,
                  },
                });
              } finally {
                this.isApplyFormSubmitLoading = false;
              }
            } else {
              this.isApplyFormSubmitLoading = true;

              await this.$api.post("api/attendances/reconciliations/apply", {
                attendance_date: this.attendanceDate,
                description: this.description,
                check_in: this.checkIn,
                check_out: this.checkOut,
              });

              this.isApplyFormSubmitLoading = false;

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Reconciliation Successfully Applied",
                },
              });
            }
            this.hiddenApplyEmployeeModal();

            this.loadItems();
          } catch (error) {
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.applyValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          } finally {
            this.isApplyFormSubmitLoading = false;
          }
        }
      });
    },

    onContextDate(ctx) {
      this.start_date = ctx.selectedYMD;
    },
    statusVariant(value) {
      if (value == APPROVED) {
        return "light-success";
      } else if (value == REJECTED || value === '') {
        return "light-danger";
      } else if (value == PENDING) {
        return "light-warning";
      }
      return "light-warning";
    },
    showDescription(value) {
      this.isPopUpDescription = true;
      this.description = value;
      this.showDescriptionModal();
    },
    showRemarks(value) {
      this.isPopUpDescription = false;
      this.description = value;
      this.showDescriptionModal();
    },

    async onApprove(value) {
      //create a flag
      this.approveButton = true;

      if (!value?.remarks) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: "Need to Add Approval Remarks",
          },
        });
      }
      await this.onShow(value);
    },

    handleApproveClick() {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Approve This?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const isRemarksDisabled =
              document.getElementById("required-remarks").disabled;

            await this.$api.put(
              `api/attendances/reconciliations/${this.attendanceReconciliationsId}`,
              {
                attendance_date: this.attendanceDate,
                description: this.description,
                check_in: this.checkIn,
                check_out: this.checkOut,
                is_remarks_disabled: isRemarksDisabled,
                remarks: this.approvalRemarks,
              }
            );

            this.isApplyFormSubmitLoading = true;
            // Perform the API POST request to approve
            await this.$api.post(
              `api/attendances/reconciliations/${this.attendanceReconciliationsId}/approve`
            );

            // Assuming 'loadItems' is a method to fetch updated data
            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Successfully Approved",
              },
            });
            this.hiddenApplyModal();
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message || "An error occurred",
              },
            });
          } finally {
            this.isApplyFormSubmitLoading = false;
          }
        }
      });
    },



    rejectModalOpen(data) {
      this.$bvModal.show("modal-reject-form");
      this.userData = data;
      this.rejectReconciliationId = data?.id;
    },
    async rejectFromSubmit() {
      this.$refs.rejectValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.$swal({
              title: "Warning!",
              text: `Are You Sure, You Want to Reject Reconciliation of ${this.userData?.user?.data?.name}?`,
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
              showCancelButton: true,
              confirmButtonText: "Ok",
              showLoaderOnConfirm: true,
            }).then(async (result) => {
              if (result.isConfirmed) {
                await this.$api.post(
                  `api/attendances/reconciliations/${this.rejectReconciliationId}/cancel`,
                  {
                    rejection_remarks: this.rejectionRemarks,
                  }
                );

                this.hiddenRejectModal();
                this.loadItems();

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Successfully Rejected",
                  },
                });
              }
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },
    getCurrentDate() {
      // Input date string
      const currentDate = new Date();

      // Extract year, month, and day components
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Add 1 to month since it's zero-based
      const day = String(currentDate.getDate()).padStart(2, "0");

      // Create the formatted date string
      const todaysDate = `${year}-${month}-${day}`;

      return todaysDate;
    },
    async loadAttendance(value) {
      if (value == "division") {
        this.selectDepartmentId = "";
        this.selectEmpId = "";
        if (this.selectDivisionId) {
          const departments = await this.getDepartments();

          this.filterDepartmentIdOption = (departments?.data?.data || []).map(
            (item) => {
              let name = item?.name;

              return {
                name,
                divisionId: item?.division_id,
                id: item?.id,
              };
            }
          );

          this.filterDepartmentIdOption = this.filterDepartmentIdOption.filter(
            (item) => item?.divisionId == this.selectDivisionId
          );
        } else {
          const departments = await this.getDepartments();

          this.filterDepartmentIdOption = (departments?.data?.data || []).map(
            (item) => {
              let name = item?.name;

              return {
                name,
                divisionId: item?.division_id,
                id: item?.id,
              };
            }
          );
        }
      }

      if (value == "department") {
        this.selectEmpId = "";
        if (this.selectDepartmentId) {
          const employees = await this.getActiveEmployees();

          this.filterEmpIdOption = (employees?.data?.data || []).map((item) => {
            let name = `${item?.name} (${item?.email})`;
            return {
              name,
              id: item?.id,
              departmentId: item?.department_id,
            };
          });

          this.filterEmpIdOption = this.filterEmpIdOption.filter(
            (item) => item?.departmentId == this.selectDepartmentId
          );
        } else {
          const employees = await this.getActiveEmployees();

          this.filterEmpIdOption = (employees?.data?.data || []).map((item) => {
            let name = `${item?.name} (${item?.email})`;
            return {
              name,
              id: item?.id,
              departmentId: item?.department_id,
            };
          });
        }
      }

      this.loadItems();
    },

    async getActiveEmployees() {
      return await this.$api.get("api/users/active-all");
    },
    async getDivisions() {
      return await this.$api.get("api/divisions/all");
    },
    async getEmployeeTypes() {
      return await this.$api.get("api/employee-types/all");
    },
    async getDepartments() {
      return await this.$api.get("api/departments/all");
    },
    formatMinutesToHours(value) {
      if (value) {
        return minutesToHours(value);
      }
    },
    formatFnTableFormateDate(value) {
      if (value) {
        return this.$moment(value).format("ll");
      }
    },
    formatFnTableCheckIn(value) {
      if (value) {
        return this.$moment(value, "HH:mm:ss").format("LT");
      }
    },
    formatTimeDff(checkin, checkout) {
      if (checkin && checkout) {
        // Parse the time strings using moment
        const checkInTime = this.$moment(checkin, "HH:mm:ss");
        const checkOutTime = this.$moment(checkout, "HH:mm:ss");

        // Calculate the difference in minutes
        const differenceInMinutes = checkOutTime.diff(checkInTime, 'minutes');

        return minutesToHours(differenceInMinutes)
      }
    },
    formatFnTableCheckOut(value) {
      if (value) {
        return this.$moment(value, "HH:mm:ss").format("LTS");
      }
    },

    showModal() {
      this.$bvModal.show("modal-update-reconciliation-view");
    },
    hiddenModal() {
      this.$bvModal.hide("modal-update-reconciliation-view");
      this.resetForm();
    },
    showDescriptionModal() {
      this.$bvModal.show("modal-description-view");
    },
    hiddenDescriptionModal() {
      this.description = "";
      this.$bvModal.hide("modal-description-view");
    },

    async getAttendanceActivity(attendanceDate) {
      let user;
      user = this.selectUserId ? this.selectUserId : this.authUser?.id;
      const attendanceActivities = await this.$api.get(
        `api/attendance-activities/${user}/${attendanceDate}`
      );

      const checkInCheckOutLog = attendanceActivities?.data?.data.sort(
        (a, b) => {
          const timeA = new Date(`${a?.date} ${a?.check_in}`);
          const timeB = new Date(`${b?.date} ${b?.check_in}`);

          // Compare and return the result
          return timeA - timeB;
        }
      );

      this.items = checkInCheckOutLog.map((item) => {
        return {
          ...item,
          check_in: item?.check_in
            ? this.$moment(item?.check_in, "HH:mm:ss").format("LTS")
            : "Invalid Time",
          check_out: item?.check_out
            ? this.$moment(item?.check_out, "HH:mm:ss").format("LTS")
            : "Invalid Time",
          worked_hour: this.formattedTime(item?.worked_minutes),
        };
      });
    },
    showApplyModal(type) {
      if (type === "apply-employee") {
        this.modelType = "";
        this.applyEmployee = true;
      }
      this.$bvModal.show("modal-apply-form");
    },
    hiddenApplyModal() {
      this.$bvModal.hide("modal-apply-form");
      this.resetApplyForm();
      this.approveButton = false;
      this.applyEmployee = false;
      this.selectUserId = "";
    },
    hiddenRejectModal() {
      this.$bvModal.hide("modal-reject-form");
      this.rejectionRemarks = "";
      this.rejectReconciliationId = "";
      this.userData = "";
    },
    resetApplyForm() {
      this.attendanceDate = "";
      this.description = "";
      this.checkIn = "";
      this.checkOut = "";
      this.items = [];
      this.approvalRemarks = "";
      this.userData = "";

    },
    resetForm() {
      this.firstCheckIn = "";
      this.lastCheckOut = "";
      this.expectedDutyHours = "";
      this.totalWorkedHours = "";
      this.overTimeHours = "";
      this.remarks = "";
    },

    onShowPage(id) {
      this.$router.push({
        name: "admin-attendance-details",
        params: { id },
      });
    },

    onOpenInNewTab(id) {
    const routeData = this.$router.resolve({ name: 'admin-attendance-details', params: { id } });
    window.open(routeData.href, '_blank');
  },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    async getAttendances(params) {
      return await this.$api.get(
        "api/attendances/reconciliations?include=user,attendanceStatus",
        {
          params: {
            show: params.show,
            page: params.page,
            sort: params.sort,
            filterStatus: params.filterStatus,
            filterDivisionId: params.filterDivisionId,
            filterEmpTypeId: params.filterEmpTypeId,
            filterDepartmentId: params.filterDepartmentId,
            filterEmpId: params.filterEmpId,
            filterStartDate: params.filterStartDate,
            filterEndDate: params.filterEndDate,
          },
        }
      );
    },
    async loadItems() {
      try {

        const { startDate, endDate } = formatDateRange(this.filterRangeDate)

        const [attendances] = await Promise.all([
          this.getAttendances({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            filterStatus: this.selectStatus,
            filterDivisionId: this.selectDivisionId,
            filterEmpTypeId: this.selectEmpTypeId,
            filterDepartmentId: this.selectDepartmentId,
            filterEmpId: this.selectEmpId,
            filterStartDate: startDate,
            filterEndDate: endDate,
          }),
        ]);

        const data = attendances?.data?.data;
        const meta = attendances?.data?.meta;

        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.table-custom-style {
  font-size: 14px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: middle !important;
  }
}
.custom-font {
  font-size: 13px !important;
}
.required-label label::after {
  content: " *";
  color: red;
}

.flatpickr-wrapper{
  width: 100% !important;
}

.flatpickr-input[readonly]:disabled {
  color: #d3d3d3 !important; /* Custom color (light gray in this case) */
  background-color: transparent !important;
  cursor: not-allowed;
}

</style>
